import React, { FunctionComponent, useState } from 'react';

import FaqAdmin from 'components/FaqAdmin';
import FaqCardSkeleton from 'components/FaqAdmin/skeleton';
import SkeletonTemplate from './skeleton';

import { 
	GridContainer,
	QAContainer,
	Subtitle,
	ErrorMessage
} from './styles';

import { IVacationsProps } from './types';
import Collapsible from './collapsible';

const Vacations: FunctionComponent<IVacationsProps> = (props: IVacationsProps) => {
	const  { loading, faqData, vacationsData }  = props;

	const [activeCard, setActiveCard] = useState<-1|0|1|2|3>(-1);
	const onClickCard = (i) => {
		return setActiveCard(activeCard === i ? -1 : i);
	}

	return (
		<QAContainer>
			<Subtitle>Esta es la información de tus PTO:</Subtitle>
			{ loading && <SkeletonTemplate /> }
			{!loading && ((vacationsData !== 'error') ?
				<GridContainer>
					<Collapsible
						cardTitle="Período actual"
						moreInfo="Este es el período sobre el que se calculan tus días de PTO. A lo largo de estos meses, deberás tomarte al menos el 70% de tus días disponibles."
						onClickCard={() => onClickCard(0)}
						isOpen={activeCard === 0}
						timeRange={
							{
								startDate: '01/10/2024',
								endDate: '30/04/2025',
							}
						}
					/>
					<Collapsible
						cardTitle="Días ya tomados"
						moreInfo="Son los días que ya usaste/reservaste de los disponibles en el período actual."
						data={Number(vacationsData[7])}
						onClickCard={() => onClickCard(1)}
						isOpen={activeCard === 1}
					/>
					<Collapsible
						cardTitle="Días disponibles"
						moreInfo="Son los días de PTO que aún tenés a disposición para tomarte dentro del período actual."
						data={Number(vacationsData[8])}
						onClickCard={() => onClickCard(2)}
						isOpen={activeCard === 2}
					/>
					<Collapsible
						cardTitle="Días acumulables"
						moreInfo="Una vez finalizado el período actual, podrás acumular hasta un 30% de tus días disponibles para gozar a lo largo del año o sumarlos al período siguiente."
						data={vacationsData[5]}
						onClickCard={() => onClickCard(3)}
						isOpen={activeCard === 3}
					/>
				</GridContainer>
			:
				<ErrorMessage>No pudimos recuperar la información de tus PTO.<br/>Por favor contacta a <a href='mailto:administracion@wearepsh.com'>Admin & People</a> para solucionarlo.</ErrorMessage>
			)}

			<Subtitle>Preguntas frecuentes:</Subtitle>
			{ loading ? (
				<>
					<FaqCardSkeleton />
					<FaqCardSkeleton />
					<FaqCardSkeleton />
				</>
			) : (
				<FaqAdmin dataList={faqData} />
			)}
		</QAContainer>
	);
};

export default Vacations;
